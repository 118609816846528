var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"home-brand-section full-width"},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v(_vm._s(_vm.$t(_vm.brandsBlock.title)))]),_vm._v(" "),_c('div',{staticClass:"home-brand-section-slider"},[_c('div',{staticClass:"swiper-slider-wrap"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper",value:(_vm.SWIPER_OPTIONS),expression:"SWIPER_OPTIONS"}],staticClass:"swiper",attrs:{"instance-name":"home-brand-section"}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.brandsBlock.brands),function(item,index){return _c('div',{key:item.name + '-' + index,staticClass:"swiper-slide relative"},[_c('nuxt-link',{attrs:{"to":_vm.localePath(
                    item && item.link && item.link.url
                      ? ("/marchi" + (item.link.url))
                      : '/'
                  )}},[_c('picture',{staticClass:"image w-full block"},[_c('source',{attrs:{"media":"(min-width: 767px)","srcset":item && item.logo && item.logo.desktop
                        ? item.logo.desktop
                        : '/homepage/brand-img.png'}}),_vm._v(" "),_c('img',{staticClass:"w-full h-auto",class:_vm.handleImageError ? 'placeholder' : '',attrs:{"src":item && item.logo && item.logo.desktop
                        ? item.logo.desktop
                        : '/homepage/brand-img.png',"alt":"brand logo"},on:{"error":_vm.handleImageError}})])])],1)}),0)])]),_vm._v(" "),_c('div',{staticClass:"s-arrow"},[_c('div',{staticClass:"swiper-button-prev button-prev",attrs:{"id":"home-brand-section-button-prev"}},[_c('SliderLeftArrow')],1),_vm._v(" "),_c('div',{staticClass:"swiper-button-next button-next",attrs:{"id":"home-brand-section-button-next"}},[_c('SliderRightArrow')],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section class="home-brand-section full-width">
    <div class="container">
      <h2>{{ $t(brandsBlock.title) }}</h2>
      <div class="home-brand-section-slider">
        <div class="swiper-slider-wrap">
          <div
            class="swiper"
            v-swiper="SWIPER_OPTIONS"
            instance-name="home-brand-section"
          >
            <div class="swiper-wrapper">
              <div
                class="swiper-slide relative"
                v-for="(item, index) in brandsBlock.brands"
                :key="item.name + '-' + index"
              >
                <nuxt-link
                  :to="
                    localePath(
                      item && item.link && item.link.url
                        ? `/marchi${item.link.url}`
                        : '/'
                    )
                  "
                >
                  <picture class="image w-full block">
                    <source
                      media="(min-width: 767px)"
                      :srcset="
                        item && item.logo && item.logo.desktop
                          ? item.logo.desktop
                          : '/homepage/brand-img.png'
                      "
                    />
                    <img
                      :src="
                        item && item.logo && item.logo.desktop
                          ? item.logo.desktop
                          : '/homepage/brand-img.png'
                      "
                      alt="brand logo"
                      @error="handleImageError"
                      :class="handleImageError ? 'placeholder' : ''"
                      class="w-full h-auto"
                    />
                  </picture>
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
        <div class="s-arrow">
          <div
            id="home-brand-section-button-prev"
            class="swiper-button-prev button-prev"
          >
            <SliderLeftArrow />
          </div>
          <div
            id="home-brand-section-button-next"
            class="swiper-button-next button-next"
          >
            <SliderRightArrow />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { directive } from 'vue-awesome-swiper';
import 'swiper/swiper.scss';
import { SliderLeftArrow, SliderRightArrow } from '~/components/General/Icons';

SwiperCore.use([Navigation, Autoplay]);

export default defineComponent({
  name: 'HomeWork',
  components: {
    SliderLeftArrow,
    SliderRightArrow,
  },
  props: {
    brandsBlock: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const SWIPER_OPTIONS = {
      loop: true,
      observer: true,
      observeParents: true,
      slidesPerView: 4,
      spaceBetween: 24,
      resizeObserver: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: `#home-brand-section-button-next`,
        prevEl: `#home-brand-section-button-prev`,
      },
      breakpoints: {
        0: {
          slidesPerView: 'auto',
          spaceBetween: 10,
          allowTouchMove: true,
          speed: 600,
          autoplay: {
            disableOnInteraction: false,
            delay: 8000,
          },
        },
        767: {
          slidesPerView: 'auto',
          spaceBetween: 15,
          speed: 600,
          autoplay: {
            disableOnInteraction: false,
            delay: 8000,
          },
        },
        1500: {
          slidesPerView: 'auto',
          spaceBetween: 24,
        },
        1900: {
          slidesPerView: 6,
          spaceBetween: 24,
        },
      },
    };

    const handleImageError = (event) => {
      event.target.src = '/icons/logo.svg';
    };

    return {
      SWIPER_OPTIONS,
      handleImageError,
    };
  },
  directives: {
    swiper: directive,
  },
});
</script>

<style lang="scss">
.home-brand-section {
  width: 100%;
  position: relative;
  padding: 6.25rem 0 6.75rem;
  @include to-tablet-max {
    padding: 4.5rem 0 4.5rem;
  }
  .container {
    @include to-tablet-max {
      // padding-right: 0;
    }
  }
  h2 {
    @include font-italic;
    margin-bottom: 2rem;
    text-align: center;
    @include to-tablet-max {
      font-size: var(--font-size-24);
      line-height: var(--line-height-24);
    }
  }
  .home-brand-section-slider {
    position: var(--relative);
    padding-top: 3rem;
    padding-bottom: 3rem;
    @include to-mobile-max {
      padding: 1rem 0;
    }
    &:before,
    &:after {
      content: '';
      position: var(--absolute);
      top: var(--top-0);
      left: var(--left-0);
      height: 0.063rem;
      width: 100%;
      background-color: var(--c-grey);
      margin-left: 50% !important;
      transform: translateX(-50%);
      min-width: 100vw;
      max-width: 100% !important;
    }
    &:after {
      top: unset;
      bottom: var(--bottom-0);
    }
  }
  .s-arrow {
    justify-content: space-between;
    @include for-mobile {
      display: var(--hidden);
    }
    .swiper-button-next,
    .swiper-button-prev {
      border: 0;
      background: none;
      padding: 0 1.25rem;
      cursor: pointer;
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translatey(-50%);
      @include for-mobile {
        display: block;
      }
      &.swiper-button-disabled {
        opacity: 0.5;
      }
      svg,
      img {
        width: 1.25rem;
        height: auto;
      }
      @include for-mobile {
        svg {
          height: 2.188rem;
          width: auto;
        }
      }
    }
    .button-prev {
      left: -1.25rem;
      @include for-mobile {
        left: 0.625rem;
      }
    }
    .button-next {
      right: -1.25rem;
      @include for-mobile {
        right: 0.625rem;
      }
    }
  }
  .swiper-slide {
    position: var(--relative);
    text-align: center;
    height: auto;
    width: 17.75rem;
    @include to-mobile-max {
      width: auto !important;
    }
    a {
      display: var(--flex);
      align-items: var(--align-items);
      justify-content: center;
      height: var(--h-full);
      .image {
        img {
          height: auto;
          line-height: 0;
          width: auto;
          max-height: 90px;
          @include to-mobile-max {
            width: auto;
          }
        }
      }
    }
  }
  .swiper-slider-wrap {
    @include to-mobile-max {
      margin-right: -0.938rem;
    }
  }
  .swiper:not(.swiper-container-initialized) {
    .swiper-wrapper {
      display: var(--flex);
      flex-wrap: nowrap;
      overflow: hidden;
      .swiper-slide {
        flex: 0 0 16.6666666667%;
        width: var(--w-full);
        margin-right: 1.5rem;
        @include to-tablet-1200 {
          margin-right: 1.5rem;
          flex: 0 0 33.3333333333%;
        }
        @include to-mobile-max {
          margin-right: 2rem;
          flex: 0 0 50%;
        }
      }
    }
  }
}
</style>
